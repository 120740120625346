import React from 'react';
import {connect} from 'react-redux';
import GUI from '../containers/gui.jsx';
import adacraft from '../adacraft';

const searchParams = new URLSearchParams(location.search);
const cloudHost = searchParams.get('cloud_host') || 'wss://clouddata.turbowarp.org';

setTimeout(() => {
    adacraft.enableProjectSaving();
}, 0);

const RenderGUI = props => (
    <GUI
        cloudHost={cloudHost}
        canSave={props.canSave}
        basePath={process.env.ROOT}
        canEditTitle
        enableCommunity
        {...props}
    />
);

const mapStateToProps = (state) => ({
    canSave: state.adacraft.canSave
})

export default connect(mapStateToProps)(RenderGUI);
