import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import SerialConnectionModalComponent, {PHASES} from '../components/serial-connection-modal/serial-connection-modal.jsx';
import VM from 'scratch-vm';
import analytics from '../lib/analytics';
import extensionData from '../lib/libraries/extensions/index.jsx';
import {connect} from 'react-redux';
import {closeSerialConnectionModal} from '../reducers/modals';

class SerialConnectionModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleConnection',
            'handleCancel'
        ]);
        this.state = {
            extension: extensionData.find(ext => ext.extensionId === props.extensionId),
            phase: props.vm.getPeripheralIsConnected(props.extensionId) ?
                PHASES.connected : PHASES.connection
        };
        this.props.vm.on('PERIPHERAL_CONNECTED', this.handleCancel);
    }
    handleConnection () {
        this.setState({
            phase: PHASES.connection
        });
    }
    handleCancel () {
        this.props.onCancel();
    }
    render () {
        return (
            <SerialConnectionModalComponent
                connectingMessage={this.state.extension && this.state.extension.connectingMessage}
                connectionIconURL={this.state.extension && this.state.extension.connectionIconURL}
                connectionSmallIconURL={this.state.extension && this.state.extension.connectionSmallIconURL}
                connectionTipIconURL={this.state.extension && this.state.extension.connectionTipIconURL}
                projectLink={this.state.extension && this.state.extension.projectLink}
                extensionId={this.props.extensionId}
                name={this.state.extension && this.state.extension.name}
                phase={this.state.phase}
                title={this.props.extensionId}
                vm={this.props.vm}
                onCancel={this.handleCancel}
                onConnection={this.handleConnection}
            />
        );
    }
}

SerialConnectionModal.propTypes = {
    extensionId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    extensionId: state.scratchGui.serialConnectionModal.extensionId
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeSerialConnectionModal());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SerialConnectionModal);
