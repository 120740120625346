import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import ConnectionStep from '../../containers/connection-step.jsx';

import styles from './serial-connection-modal.css';

const PHASES = keyMirror({
    connection: null
});

const SerialConnectionModalComponent = props => (
    <Modal
        className={styles.modalContent}
        contentLabel={props.name}
        headerClassName={styles.header}
        headerImage={props.connectionSmallIconURL}
        id="serialConnectionModal"
        onRequestClose={props.onCancel}
    >
        <Box className={styles.body}>
            {props.phase === PHASES.connection && <ConnectionStep {...props} />}
        </Box>
    </Modal>
);

SerialConnectionModalComponent.propTypes = {
    name: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired,
    title: PropTypes.string.isRequired
};

SerialConnectionModalComponent.defaultProps = {
    connectingMessage: 'Connecting'
};

export {
    SerialConnectionModalComponent as default,
    PHASES
};
