exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".monitor-list_monitor-list_20k-y {\n    /* Width/height are set by the component, margin: auto centers in fullscreen */\n    margin: auto;\n    pointer-events: none;\n    overflow: hidden;\n}\n\n.monitor-list_monitor-list-scaler_143tA {\n    /* Scaling for monitors should happen from the top left */\n    transform-origin: left top;\n}\n\n::-ms-clear { display: none; }\n", ""]);

// exports
exports.locals = {
	"monitor-list": "monitor-list_monitor-list_20k-y",
	"monitorList": "monitor-list_monitor-list_20k-y",
	"monitor-list-scaler": "monitor-list_monitor-list-scaler_143tA",
	"monitorListScaler": "monitor-list_monitor-list-scaler_143tA"
};