import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Box from '../box/box.jsx';
import checkIcon from './icons/check.png';
import crossIcon from './icons/cross.png';
import infosIcon from './icons/infos.png';
import styles from './serial-connection-modal.css';

const ConnectionStep = props => (
    
    <Box className={styles.body}>
        {navigator.serial ? (
            <div>
                <Box className={styles.activityArea}>
                    <div className={styles.activityAreaInfo}>
                        <div className={styles.centeredRow}>
                            <img
                                className={styles.checkSerialIcon}
                                src={checkIcon}
                            />
                            <FormattedMessage
                                defaultMessage="Your browser is compatible with this extension"
                                description="Text shown if navigator does not support Serial API"
                                id="gui.connection.serial.hasSerialAPI"
                            />
                            <div className={styles.serialpopup}>
                                <img
                                    className={styles.infosSerialIcon}
                                    src={infosIcon}
                                    onClick={props.onSerialPopupOpening}
                                />
                                <span className={styles.serialpopuptext} id="serialPopup">
                                    <FormattedMessage
                                        defaultMessage="Vittascience's micro:bit extension uses Serial API technology to communicate between the browser and the BBC micro:bit board."
                                        description="Text shown to help user when clicking on serial popup icon"
                                        id="gui.connection.serial.serialPopup"
                                    />
                                    <br></br>
                                    <a target="_blank" delay="100" href="https://developer.mozilla.org/en-US/docs/Web/API/Serial#browser_compatibility">
                                        <FormattedMessage
                                            defaultMessage="See the list of compatible browsers."
                                            description="Text shown to redirect user to serial API compatible browsers page"
                                            id="gui.connection.serial.browsersList"
                                        />
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </Box>
                <Box className={styles.bottomArea}>
                    <div>
                        <FormattedMessage
                            defaultMessage="To interact with micro:bit, please download the following program and transfer it into your micro:bit"
                            description="Text shown while connecting board"
                            id="gui.connection.serial.downloadingHexFile"
                        />
                        <div className={styles.downloadpopup}>
                            <img
                                className={styles.infosSerialIcon}
                                src={infosIcon}
                                onClick={props.onDownloadPopupOpening}
                            />
                            <span className={styles.downloadpopuptext} id="downloadPopup">
                                <FormattedMessage
                                    defaultMessage="To transfer the program into your micro:bit, follow these steps:"
                                    description="Text shown to help user when clicking on download popup icon - intro"
                                    id="gui.connection.serial.downloadPopup.intro"
                                />
                                <br></br><br></br>
                                <FormattedMessage
                                    defaultMessage="1. Connect your micro:bit to your computer with a USB cable. Your computer should detect it as a new USB device called MICROBIT."
                                    description="Text shown to help user when clicking on download popup icon - step1"
                                    id="gui.connection.serial.downloadPopup.step1"
                                />
                                <br></br>
                                <FormattedMessage
                                    defaultMessage="2. Click Download .hex to download the .hex file and locate it on your computer."
                                    description="Text shown to help user when clicking on download popup icon - step2"
                                    id="gui.connection.serial.downloadPopup.step2"
                                />
                                <br></br>
                                <FormattedMessage
                                    defaultMessage="3. Move the .hex file from your computer onto the MICROBIT drive."
                                    description="Text shown to help user when clicking on download popup icon - step3"
                                    id="gui.connection.serial.downloadPopup.step3"
                                />
                                <br></br>
                            </span>
                        </div>
                    </div>
                    <div className={styles.centeredRow,styles.marginBottom}>
                        <button
                            className={classNames(styles.connectionButton)}
                            onClick={props.onDownloading}
                        >
                            <FormattedMessage
                                defaultMessage="Download .hex"
                                description="Button in prompt for downloading .hex file"
                                id="gui.connection.serial.downloadHexButton"
                            />
                        </button>
                    </div>
                    <div className={styles.centeredRow, styles.marginBottom}>
                        <FormattedMessage
                            defaultMessage="To see and modify this program on Vittascience, "
                            description="Text shown for redirecting to python source code"
                            id="gui.connection.serial.checkSourceCode"
                        />
                        <a target="_blank" delay="100" href={props.projectLink}>
                            <FormattedMessage
                                defaultMessage="click here"
                                description="Text shown click here button"
                                id="gui.connection.serial.clickHere"
                            />
                        </a>
                    </div>
                </Box>
                <Box className={styles.activityAreaBis}>
                    <FormattedMessage
                        defaultMessage="Connect your micro:bit to the browser"
                        description="Text shown while connecting board"
                        id="gui.connection.serial.connectBoard"
                    />
                    <div className={styles.connectpopup}>
                        <img
                            className={styles.infosSerialIcon}
                            src={infosIcon}
                            onClick={props.onConnectPopupOpening}
                        />
                        <span className={styles.connectpopuptext} id="connectPopup">
                            <FormattedMessage
                                defaultMessage="If you don't see your micro:bit in the list, check that it is correctly plugged by USB  and try again. If you are using your micro:bit on other interfaces, try to disconnect them and try again."
                                description="Text shown to help user when clicking on connect popup icon"
                                id="gui.connection.serial.connectPopup"
                            />
                        </span>
                    </div>
                </Box>
                <Box className={styles.activityAreaBis}>
                    <div className={styles.centeredRow}>
                        <button
                            className={classNames(styles.connectionButton)}
                            onClick={props.onConnecting}
                        >
                            <FormattedMessage
                                defaultMessage="Connect"
                                description="Button in prompt for connecting a board"
                                id="gui.connection.serial.connectButton"
                            />
                        </button>
                    </div>
                </Box>
            </div>
        ) : (
                <Box className={styles.activityArea}>
                    <div className={styles.activityAreaInfo}>
                        <div className={styles.centeredRow}>
                            <img
                                className={styles.crossSerialIcon}
                                src={crossIcon}
                            />
                            <FormattedMessage
                                defaultMessage="Your browser does not support Serial API"
                                description="Text shown if navigator does not support Serial API"
                                id="gui.connection.serial.noSerialAPI"
                            />
                            <div className={styles.serialpopup}>
                                <img
                                    className={styles.infosSerialIcon}
                                    src={infosIcon}
                                    onClick={props.onSerialPopupOpening}
                                />
                                <span className={styles.serialpopuptext} id="serialPopup">
                                    <FormattedMessage
                                        defaultMessage="Vittascience's micro:bit extension uses Serial API technology to communicate between the browser and the BBC micro:bit board."
                                        description="Text shown to help user when clicking on serial popup icon"
                                        id="gui.connection.serial.serialPopup"
                                    />
                                    <br></br>
                                    <a target="_blank" delay="100" href="https://developer.mozilla.org/en-US/docs/Web/API/Serial#browser_compatibility">
                                        <FormattedMessage
                                            defaultMessage="See the list of compatible browsers."
                                            description="Text shown to redirect user to serial API compatible browsers page"
                                            id="gui.connection.serial.browsersList"
                                        />
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </Box>
            )
        }
    </Box>
);

ConnectionStep.propTypes = {
    onConnecting: PropTypes.func,
    onDownloading: PropTypes.func,
};

export default ConnectionStep;
