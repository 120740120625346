var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .checkbox_checkbox_2QbUl {\n    -webkit-appearance: none;\n       -moz-appearance: none;\n            appearance: none;\n    width: 18px;\n    height: 18px;\n    border-radius: 4px;\n    transition: .2s;\n    background-color: white;\n    transition-property: background-color;\n    background-size: 100%;\n    border: 1px solid rgb(92, 92, 92);\n    outline: none;\n} .checkbox_checkbox_2QbUl:hover, .checkbox_checkbox_2QbUl:focus, .checkbox_checkbox_2QbUl:active, .checkbox_checkbox_2QbUl:checked {\n    border-color: hsla(215, 100%, 65%, 1);\n} .checkbox_checkbox_2QbUl:focus, .checkbox_checkbox_2QbUl:active {\n    box-shadow: 0 0 0 3px hsla(215, 100%, 65%, 0.35);\n} .checkbox_checkbox_2QbUl:checked {\n    background-color: hsla(215, 100%, 65%, 1);\n    background-image: url(" + escape(require("./checkbox.svg")) + ");\n} [theme=\"dark\"] .checkbox_checkbox_2QbUl {\n    border-color: transparent;\n}\n", ""]);

// exports
exports.locals = {
	"checkbox": "checkbox_checkbox_2QbUl"
};