import xhr from 'xhr';
import { manualUpdateProject, setProjectId } from '../reducers/project-state';
import {
    activateTab,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX
} from '../reducers/editor-tab';
import {
    enableProjectSaving,
    disableProjectSaving
} from './reducers/adacraft';
import { selectLocale } from '../reducers/locales';

const adacraftStorage = {
    defaultProjectHost: 'https://storage.adacraft.org/projects',
    defaultAssetHost: 'https://storage.adacraft.org/assets',
    // Value of "withCredentials" option for all XHR requests. We set it to
    // "false" to avoid CORS errors with adacraft backend.
    withCredentials: false,
    getProjectGetConfig (project) {
        return `${adacraftStorage.defaultProjectHost}/${project.assetId}`;
    },
    getAssetGetConfig (asset) {
        return `${adacraftStorage.defaultAssetHost}/${asset.assetId}.${asset.dataFormat}`;
    },
    getAssetCreateConfig (asset) {
        return {
            method: 'put',
            url: `${adacraftStorage.defaultAssetHost}/${asset.assetId}.${asset.dataFormat}`,
            withCredentials: adacraftStorage.withCredentials
        };
    },

    /**
     * Save a project JSON to the project server.
     * @param {number} projectId the ID of the project, null if a new project.
     * @param {object} projectJson the JSON project representation.
     * @param {object} params the request params.
     * @property {?number} params.originalId the original project ID if a copy/remix.
     * @property {?boolean} params.isCopy a flag indicating if this save is creating a copy.
     * @property {?boolean} params.isRemix a flag indicating if this save is creating a remix.
     * @property {?string} params.title the title of the project.
     * @return {Promise} A promise that resolves when the network request resolves.
     */
    saveProjectToServer (projectId, projectJson, params) {
        const options = {
            method: 'put',
            url: `${adacraftStorage.defaultProjectHost}/${projectId}`,
            body: projectJson,
            // If we set json:true then the body is double-stringified, so don't
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: adacraftStorage.withCredentials
        };
        return new Promise((resolve, reject) => {
            // @ts-ignore
            xhr(options, (error, response) => {
                if (error) {
                    return reject(error);
                }
                // Storage for adacraft returns with 201 status
                if (response.statusCode !== 200 && response.statusCode !== 201) {
                    return reject(response.statusCode);
                }
                let body;
                try {
                    // Since we didn't set json: true, we have to parse manually
                    // @ts-ignore
                    body = JSON.parse(response.body);
                } catch (e) {
                    return reject(e);
                }
                // Storage for adacraft returns an empty body
                if (!body) {
                    body = {};
                }
                body.id = projectId;
                resolve(body);
            });
        });
    }
}

const storageManager = {
    add (name, storage) {
        adacraft.storage = storage
    }
};

const defaultAdacraftPreInitConfig = {
    loadProjectOnStartup: true,
    // A callback that will be called with a reference of the global adacraft
    // module object so external tools can get and store a reference to it.
    onModuleReady: (adacraftModule) => {},
    noDefaultStorage: false
}

// @ts-ignore
const adacraftPreInitConfig = window.adacraftPreInitConfig || defaultAdacraftPreInitConfig

const adacraft = {
    config: {
        loadProjectOnStartup: adacraftPreInitConfig.loadProjectOnStartup
    },

    // A link to the UI instance for the menu bar. For example, to access some
    // of it menu actions (like save on comuter) that are stored in the props.
    menuBar: null,

    // The following property is a reference to the main app state store. It is
    // the low level way to get information and change the app behavior, like
    // changing the current project content or allowing the saving of the
    // project:
    //
    //      adacraft.appStateStore.dispatch({
    //          type: 'adacraft/ENABLE_PROJECT_SAVING'
    //      })
    //
    // This property will be updated when the store is created in
    // app-state-hoc.js (at the time of this writing).
    appStateStore: null,

    switchToDarkTheme: () => {},
    switchToLightTheme: () => {},

    // Mainly used by the main app store to add adacraft stuff.
    getAdacraftReducers () {
        const adacraftRedux = require('./reducers/adacraft')
        return adacraftRedux.default
    },

    enableProjectSaving () {
        this.appStateStore.dispatch(enableProjectSaving())
    },
    
    disableProjectSaving () {
        this.appStateStore.dispatch(disableProjectSaving())
    },

    getCurrentProjectJson () {
        const state = this.appStateStore.getState()
        return state.scratchGui.vm.toJSON()
    },

    loadProjectFromComputer () {
        this.menuBar.props.onStartSelectingFileUpload()
    },

    saveProjectToComputer () {
        this.menuBar.props.handleSaveProject()
    },

    askLoadingOfProjectById (projectId) {
        this.appStateStore.dispatch(setProjectId(projectId))
    },

    saveCurrentProject () {
        this.appStateStore.dispatch(manualUpdateProject())
    },

    tabs: {
        BLOCKS_TAB_INDEX,
        COSTUMES_TAB_INDEX,
        SOUNDS_TAB_INDEX,

        switchToTab (tabIndex) {
            adacraft.appStateStore.dispatch(activateTab(tabIndex))
        }
    },

    setLocale (locale) {
        adacraft.appStateStore.dispatch(selectLocale(locale))
    },

    greenFlag () {
        const state = this.appStateStore.getState();
        const vm =  state.scratchGui.vm;
        vm.start();
        vm.greenFlag();
    },

    undo () {
        const workspace = Blockly.getMainWorkspace();
        workspace.undo();
    },

    redo () {
        const workspace = Blockly.getMainWorkspace();
        const redo = true;
        workspace.undo(redo);
    },

    watchAppState (stateValueGetter, onChange) {
        const store = this.appStateStore;
        let previousValue;
        const checkStateChange = () => {
            const currentValue = stateValueGetter(store.getState());
            if (currentValue !== previousValue) {
                onChange(currentValue, previousValue);
                previousValue = currentValue;
            }
        }
        const unwatch = store.subscribe(checkStateChange);
        checkStateChange();
        return unwatch;
    },

    watchProjectChanged (onChange) {
        const unwatch = this.watchAppState(
            (state) => (state.scratchGui.projectChanged),
            onChange
        );
        return unwatch;
    },


    storage: {},

    storageManager
}

if (!adacraftPreInitConfig.noDefaultStorage) {
    adacraft.storageManager.add('adacraft', adacraftStorage);
}
adacraftPreInitConfig.onModuleReady(adacraft)

export default adacraft
