import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ConnectionStepComponent from '../components/serial-connection-modal/connection-step.jsx';
import VM from 'scratch-vm';

class ConnectionStep extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleConnecting',
            'handleDownloading',
            'toggleSerialPopup',
            'toggleDownloadPopup',
            'toggleConnectPopup'
        ]);
        this.state = {
            serialPopupShown: false,
            downloadPopupShown: false,
            connectPopupShown: false
        };
    }
    handleConnecting () {
        this.props.vm.connectSerialPeripheral(this.props.extensionId);
    }
    handleDownloading () {
        this.props.vm.downloadCodeFile(this.props.extensionId);
    }
    toggleSerialPopup () {
        document.getElementById("serialPopup").style.visibility = this.state.serialPopupShown ? "hidden" : "visible";
        this.state.serialPopupShown = !this.state.serialPopupShown;
    }
    toggleDownloadPopup () {
        document.getElementById("downloadPopup").style.visibility = this.state.downloadPopupShown ? "hidden" : "visible";
        this.state.downloadPopupShown = !this.state.downloadPopupShown;
    }
    toggleConnectPopup () {
        document.getElementById("connectPopup").style.visibility = this.state.connectPopupShown ? "hidden" : "visible";
        this.state.connectPopupShown = !this.state.connectPopupShown;
    }
    render () {
        return (
            <ConnectionStepComponent
                phase={this.state.phase}
                title={this.props.extensionId}
                onConnecting={this.handleConnecting}
                onDownloading={this.handleDownloading}
                onSerialPopupOpening={this.toggleSerialPopup}
                onDownloadPopupOpening={this.toggleDownloadPopup}
                onConnectPopupOpening={this.toggleConnectPopup}
                projectLink={this.props.projectLink}
            />
        );
    }
}

ConnectionStep.propTypes = {
    extensionId: PropTypes.string.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

export default ConnectionStep;
