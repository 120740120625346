import ScratchStorage from 'scratch-storage';

import defaultProject from './default-project';
import missingProject from './tw-missing-project';
import log from './log';

import adacraft from '../adacraft';

/**
 * Wrapper for ScratchStorage which adds default web sources.
 * @todo make this more configurable
 */
class Storage extends ScratchStorage {
    constructor () {
        super();
        this.cacheDefaultProject();
    }
    addOfficialScratchWebStores () {
        this.addWebStore(
            [this.AssetType.Project],
            this.getProjectGetConfig.bind(this),
            this.getProjectCreateConfig.bind(this),
            this.getProjectUpdateConfig.bind(this)
        );
        this.addWebStore(
            [this.AssetType.ImageVector, this.AssetType.ImageBitmap, this.AssetType.Sound],
            this.getAssetGetConfig.bind(this),
            // We set both the create and update configs to the same method because
            // storage assumes it should update if there is an assetId, but the
            // asset store uses the assetId as part of the create URI.
            this.getAssetCreateConfig.bind(this),
            this.getAssetCreateConfig.bind(this)
        );
    }
    // All storage options are now managed in the adacraft module
    // setProjectHost (projectHost) {
    //     this.projectHost = projectHost;
    // }
    getProjectGetConfig (projectAsset) {
        return adacraft.storage.getProjectGetConfig(projectAsset);        
    }
    getProjectCreateConfig () {
        throw Error(
            'storage.getProjectCreateConfig isn\'t implemented yet for adacraft'
        )
        // return {
        //     url: `${this.projectHost}/`,
        //     withCredentials: true
        // };
    }
    getProjectUpdateConfig (projectAsset) {
        throw Error(
            'storage.getProjectUpdateConfig isn\'t implemented yet for adacraft'
        )
        // return {
        //     url: `${this.projectHost}/${projectAsset.assetId}`,
        //     withCredentials: true
        // };
    }
    // All storage options are now managed in the adacraft module
    // setAssetHost (assetHost) {
    //     this.assetHost = assetHost;
    // }
    getAssetGetConfig (asset) {
        return adacraft.storage.getAssetGetConfig(asset);
    }
    getAssetCreateConfig (asset) {
        return adacraft.storage.getAssetCreateConfig(asset);
    }
    setTranslatorFunction (translator) {
        this.translator = translator;
        this.cacheDefaultProject();
    }
    cacheDefaultProject () {
        const defaultProjectAssets = defaultProject(this.translator);
        defaultProjectAssets.forEach(asset => this.builtinHelper._store(
            this.AssetType[asset.assetType],
            this.DataFormat[asset.dataFormat],
            asset.data,
            asset.id
        ));
        const missingProjectAssets = missingProject(this.translator);
        missingProjectAssets.forEach(asset => this.builtinHelper._store(
            this.AssetType[asset.assetType],
            this.DataFormat[asset.dataFormat],
            asset.data,
            asset.id
        ));
    }
    async load (assetType, asset, assetFormat) {
        let error;
        for (let i = 0; i < 3; i++) {
            try {
                return await super.load(assetType, asset, assetFormat);
            } catch (e) {
                // Store the first error so that we can re-throw it later if needed
                if (i === 0) {
                    error = e;
                }
                log.warn(`Attempt to get ${asset} failed, trying again`, e);
                // Wait a little bit before trying again
                await new Promise(resolve => setTimeout(resolve, (i + 1) * 1000 * Math.random()));
            }
        }
        throw new Error(`Cannot fetch asset: ${error}`);
    }
}

const storage = new Storage();

export default storage;
